import React from 'react';

export default function Layout() {
    return (
        <div className="w-full xl:h-screen">
            <div className="flex flex-col justify-center items-center h-full xl:flex-row xl:flex-row space-y-16">
                <div className="flex-1 justify-center items-center space-y-8 max-w-4xl mx-16 xl:pr-48 pt-16 xl:pt-0">
                    <h1 className="text-3xl font-thin text-center uppercase tracking-wider">
                        Kindertagespflege Tilia
                    </h1>

                    <p>
                        Ich bin Katrin, seit 1999 begleite ich Kinder als <span className='font-semibold'>Erzieherin</span> in ihrer Entwicklung. Das <span className='font-semibold'>Montessori</span> Diplom für 0-6 Jährige habe ich 2012 erworben. Dieses spiegelt sich in meiner Arbeit mit Kindern wider, denn es ist mir wichtig, Kindern eine Umgebung zu schaffen, in der sie selbstwirksam ihren Interessen nachgehen können. Seit Beginn meiner Arbeit staune ich immer wieder, wie kompetent, begeisterungsfähig und wertfrei Kinder ihre Umgebung erkunden und ihren Wissensdurst stillen.
                    </p>

                    <p>
                        Eine Fortbildung 2022 im Sinne <span className='font-semibold'>Emmi Piklers</span> führte mein inneres Empfinden für die Bedeutung der <span className='font-semibold'>bedürfnisvollen Pflege und der freien Bewegungsentwicklung und des freien Spiels</span> mit theoretischem Fachwissen wider. Mir liegt es am Herzen, dass die Kinder sich bei mir sicher und wohl fühlen. Ich begleite sie mit ihren Gefühlen, im Sinne der <span className='font-semibold'>Co-Regulation</span>. Ich sehe tatsächlich in der <span className='font-semibold'>liebevollen Begleitung und Zugewandtheit</span>, eine Möglichkeit für gesellschaftlichen Wandel, der in der Zukunft eine friedlichere Gemeinschaft bilden könnte.
                    </p>

                    <p>
                        Wer mit sich selbst im Frieden ist, braucht im Außen nicht zu kämpfen. Wer so sein darf wie er ist, verlangt auch von anderen nicht, in irgendeine Schublade zu passen. <span className='italic'>„Ich glaube daran, dass das größte Geschenk, das ich von jemandem empfangen kann, ist, gesehen, gehört, verstanden und berührt zu werden. Das größte Geschenk, das ich geben kann, ist, den anderen zu sehen, zu hören und zu berühren. Wenn dies geschieht, entsteht Kontakt“</span> - Virginia Satir.
                    </p>

                    <p>
                        Eine wertschätzende Sprache ist mir mit den Kindern wichtig. Ich übe mich in der <span className='font-semibold'>gewaltfreien Kommunikation</span> und lerne noch dazu. Ich schaffe eine <span className='font-semibold'>Ja-Umgebung</span>, im Sinne der Raumgestaltung, sowie auch sprachlich, indem ich den Kindern sage, was sie machen können, statt was sie nicht machen sollen. Z.B. „wirf den Sand dahin, wo niemand steht.“ statt „wirf nicht auf Kind A.“ Wir sind Menschen gleichwertig von Anfang an, wir brauchen uns gegenseitig mit wohlwollendem Blick aufeinander. Jeder ist genauso gut wie er ist, jeder trägt eine Superkraft in sich und trägt zu einem vielfältigen Leben bei.
                    </p>

                    <p>
                        Eine <span className='font-semibold'>behutsame Eingewöhnung</span> ist essentiell für eine <span className='font-semibold'>vertrauensvolle Beziehung</span> zu mir. Das Kind und seine Bezugsperson bestimmen mit mir zusammen den Ablöseprozess. Seit Mai 2022 bin ich zertifizierte Kindertagespflegeperson und biete in unserem Haus mit Garten in Taunusstein Wehen eine familiennahe Betreuung für bis zu 5 Kindern im Alter von 1-3 Jahren an. Hier lebe ich mit meinem Sohn und meinem Mann. Mit unserem Bollerwagen sind wir oft im nahen Wald oder den Spielplätzen in der Nähe unterwegs.
                    </p>

                    <p className='font-semibold'>
                        Die Betreuungszeiten sind Mo-Mi 8-14 Uhr. Es gibt ein Frühstück, Snack und selbstgekochtes Mittagessen. Die Ernährung ist Bio, zuckerfrei und vegetarisch.
                    </p>

                    <p>
                        Bei Interesse nehmen Sie gerne Kontakt per E-Mail unter <a href="mailto:katrin@tilia-wehen.de" className='hover:underline'>katrin@tilia-wehen.de</a> auf. Ich freue mich auf Sie und Ihr Kind!
                    </p>

                    <p className='italic'>
                        Katrin Micklisch · Kindertagespflege Tilia in Taunustein Wehen
                    </p>
                </div>

                <div className="xl:fixed right-16 xl:top-1/2 xl:transform xl:-translate-y-1/2 w-full xl:w-auto max-w-4xl">
                    <ul>
                        <li className="text-center xl:text-left">Mainzer Allee 23 (Wehen)</li>
                        <li className="text-center xl:text-left">von Mo-Mi von 08-14:00 Uhr</li>
                        <li className="text-center xl:text-left">für Kinder von 1 bis 3 Jahren</li>
                        <li className="text-center xl:text-left"><a href="mailto:katrin@tilia-wehen.de" className='hover:underline'>katrin@tilia-wehen.de</a></li>
                        <li className='my-8'><img src="/assets/raum-01.webp" className='w-full xl:w-72 h-auto border border-grey-300' /></li>
                        <li>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2556.433832827665!2d8.182502113463459!3d50.1530225086142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bdb92400806c5d%3A0xa885b1921d7cba42!2sKindertagespflege%20Tilia!5e0!3m2!1sde!2sde!4v1712144352733!5m2!1sde!2sde"
                                className='w-full h-72 xl:w-72 xl:h-48 mt-8 border border-grey-300'
                                allowFullScreen
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}